import { FormlyFieldConfig } from '@ngx-formly/core';

export class RcgValidationMessages {
  static invalidAcObject = 'Napačna izbrana vrednost';
  static required = 'Obvezno polje';
  static requiredField = 'Obvezno polje';
}

export function minLengthValidationMessage(_err: unknown, field: FormlyFieldConfig) {
  return `Mora vsebovati najmanj ${field.props?.minLength ?? ''} znakov`;
}

export function maxLengthValidationMessage(_err: unknown, field: FormlyFieldConfig) {
  return `Mora vebovati manj kot ${field.props?.maxLength ?? ''} znakov`;
}

export function emailValidatorMessage() {
  return `Ni pravilen email naslov`;
}

export function currencyValidatorMessage(error: Record<string, unknown> | undefined): string {
  if (error) {
    return `Allowed max ${error.max} decimal places.`;
  }
  return 'Invalid currency format.';
}

export function maxAllowedAttachmentsValidatorMessage(error: Record<string, unknown> | undefined): string {
  if (error) {
    return `Max allowed attachments ${error}.`;
  }
  return 'Max allowed attachments error.';
}
