<div
  [ngStyle]="{
    display: 'flex',
    'flex-direction': 'column',
    height: '100%',
    width: '100%',
    border: props.border ? '1px solid #ccc' : 'none',
    padding: props.padding !== undefined ? props.padding : '10px',
    margin: props.margin !== undefined ? props.margin : '0px'
  }"
>
  @if(props.label){
  <div style="font-weight: bold; font-size: 18px">
    {{ props.label }}
  </div>
  }
  <ng-container #fieldComponent></ng-container>
</div>
