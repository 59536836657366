import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { FieldTypeConfig, FieldWrapper } from '@ngx-formly/core';
import { FormlyFieldProps } from '@ngx-formly/material/form-field';

interface FormContainerSettings extends FormlyFieldProps {
  border?: boolean;
  padding?: number;
  margin?: number;
}

export type FormContainerConfig = FieldTypeConfig<FormContainerSettings>;
@Component({
  selector: 'rcg-form-container',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './form-container-wrapper.component.html',
  styleUrl: './form-container-wrapper.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class FormContainerWrapperComponent extends FieldWrapper {}
