import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AbstractControl } from '@angular/forms';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { FieldWrapper } from '@ngx-formly/core';
import { IntlService } from '@rcg/intl';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  selector: 'rcg-formly-field-wrapper--multi-language',
  templateUrl: './multi-language.component.html',
  styleUrl: './multi-language.component.scss',
  imports: [CommonModule, MatButtonToggleModule],
})
export class MultiLanguageFieldWrapperComponent extends FieldWrapper implements OnInit, OnDestroy {
  constructor(public readonly intlService: IntlService) {
    super();
  }

  private value: Record<string, unknown> = {};
  defaultLanguage = toSignal(this.intlService.locale$);
  currentLanguage = this.defaultLanguage();

  formControlML: AbstractControl | null = null;
  fieldChangeSubscription: Subscription | undefined;

  ignoreFieldChangeEvent = 0;

  ngOnInit() {
    this.formControlML = this.form.get(this.props.settings['mlKey']);

    this.value = this.formControlML?.value ?? {};

    if (this.currentLanguage && this.value[this.currentLanguage]) {
      const val = this.value[this.currentLanguage];
      this.field.formControl?.setValue(val);
    }

    this.fieldChangeSubscription = this.field.form?.get(this.field.key as string)?.valueChanges.subscribe((v) => {
      if (this.ignoreFieldChangeEvent === 0) {
        if (this.currentLanguage) {
          this.value[this.currentLanguage] = v;
          this.formControlML?.setValue(this.value);
        }
      } else {
        this.ignoreFieldChangeEvent = this.field.type === 'htmleditor' ? this.ignoreFieldChangeEvent - 1 : 0;
      }
    });
  }

  onLanguageChanged(event: MatButtonToggleChange) {
    this.field.focus = false;

    if (this.field.type === 'htmleditor') {
      setTimeout(() => {
        this.updateFieldValues(event.value);
      }, 500);
    } else {
      this.updateFieldValues(event.value);
    }
  }

  updateFieldValues(lang: string) {
    const currentValue = this.field.formControl?.value;
    const newLang = lang;

    if (this.currentLanguage !== newLang && this.currentLanguage !== undefined) {
      this.ignoreFieldChangeEvent = this.field.type === 'htmleditor' ? 4 : 1;
      this.value[this.currentLanguage!] = currentValue;

      const newVal = this.value[newLang];

      if (newVal) {
        this.field.formControl?.setValue(newVal);
      } else {
        if (this.field.type === 'htmleditor') {
          this.field.formControl?.setValue({ html: '', plain: '', eml_id: null });
        } else {
          this.field.formControl?.setValue('');
        }
      }

      this.formControlML?.setValue(this.value);

      this.currentLanguage = newLang;

      this.field.focus = true;
    }
  }

  ngOnDestroy() {
    this.fieldChangeSubscription?.unsubscribe();
  }
}
