import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  computed,
  HostBinding,
  inject,
  OnDestroy,
  OnInit,
  signal,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RcgFieldType } from '@rcg/core';
import { concat, delay, of } from 'rxjs';

const fieldMap: Record<
  string,
  {
    loadComponent: () => Promise<Type<RcgFieldType<unknown, object>>>;
    componentPromise?: Promise<Type<RcgFieldType<unknown, object>>>;
  }
> = {};

export const registerLazyLoadField = (name: string, loadComponent: () => Promise<Type<RcgFieldType<unknown, object>>>) => {
  fieldMap[name] = { loadComponent };
};

@Component({
  selector: 'rcg-lazy-load-field',
  standalone: true,
  imports: [MatProgressSpinnerModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @if (error()) {
    <div class="error">{{ error() }}</div>
    } @else if (loading()) {
    <mat-spinner mode="indeterminate" [diameter]="16"></mat-spinner>
    }
  `,
  styles: `
    .error {
      color: red;
    }
  `,
})
export class LazyLoadFieldComponent extends RcgFieldType implements OnInit, OnDestroy {
  private vcRef = inject(ViewContainerRef);
  private cdRef = inject(ChangeDetectorRef);

  private readonly _loading = toSignal(concat(of(false), of(true).pipe(delay(200))));
  private readonly _loaded = signal(false);

  readonly loading = computed(() => this._loading() && !this._loaded());
  readonly error = signal<unknown>(null);

  private lazyComponentRef: ComponentRef<RcgFieldType<unknown, object>> | undefined;

  @HostBinding('class.loaded')
  public get loaded() {
    return this._loaded();
  }

  async ngOnInit() {
    const fieldType = this.field.type;

    if (typeof fieldType !== 'string') {
      this.error.set(`Invalid lazy load field type "${fieldType}"`);
      return;
    }

    const fm = fieldMap[fieldType];

    if (!fm) {
      this.error.set(`Lazy load field "${fieldType}" not registered`);
      return;
    }

    const Field: Type<RcgFieldType<unknown, object>> | undefined = await (fm.componentPromise ??
      (fm.componentPromise = fm.loadComponent()));

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const thisRef = this;

    class LazyField extends Field {
      override get model(): typeof thisRef.model {
        return thisRef.model;
      }

      override get form(): typeof thisRef.form {
        return thisRef.form;
      }

      override get options(): typeof thisRef.options {
        return thisRef.options;
      }

      override get key(): typeof thisRef.key {
        return thisRef.key;
      }

      override get formControl(): typeof thisRef.formControl {
        return thisRef.formControl;
      }

      override get props(): typeof thisRef.props {
        return thisRef.props;
      }

      /** @deprecated Use `props` instead. */
      override get to(): typeof thisRef.props {
        return thisRef.to;
      }

      override get showError(): typeof thisRef.showError {
        return thisRef.showError;
      }

      override get id(): typeof thisRef.id {
        return thisRef.id;
      }

      override get formState(): typeof thisRef.formState {
        return thisRef.formState;
      }

      override get value(): typeof thisRef.value {
        return thisRef.formControl.value;
      }

      override set value(value: typeof thisRef.value) {
        thisRef.formControl.setValue(value);
      }
    }

    this._loaded.set(true);
    this.cdRef.markForCheck();

    this.lazyComponentRef = this.vcRef.createComponent(LazyField);
    this.lazyComponentRef.setInput('field', this.field);
    this.lazyComponentRef.changeDetectorRef.detectChanges();

    this.vcRef.insert(this.lazyComponentRef.hostView);
  }

  override ngOnDestroy() {
    this.lazyComponentRef?.destroy();
  }
}
