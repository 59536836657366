<div class="wrapper">
  @if (intlService.availableLanguages$ | async; as languages) {
  <mat-button-toggle-group (change)="onLanguageChanged($event)" [value]="currentLanguage" class="buttons">
    @for (lang of languages; track $index) {
    <mat-button-toggle [value]="lang.code">{{ lang.code }}</mat-button-toggle>
    }
  </mat-button-toggle-group>
  }
  <ng-container #fieldComponent class="field"></ng-container>
</div>
