<div style="display: flex; justify-content: space-between; width: 100%; align-items: center">
  <div style="flex: 1">
    <ng-container #fieldComponent></ng-container>
  </div>
  <div style="display: flex; align-items: center">
    @for(action of actions | visibleFieldActions: value:field;track $index){
    <mat-icon [matTooltip]="(action.tooltip | tooltip | async) ?? ''" class="field-icon-btn" (click)="executeAction(action)">{{
      action.icon
    }}</mat-icon>
    }
  </div>
</div>
